<template>
    <div class="row">
        <div class="col-md-18 px-3 mb-3">
            <section class="section">
                <div class="section-title">
                    <h3><i class="bi bi-calendar-check text-primary"></i> 本日アプローチ (自分が担当)</h3>
                </div>
                <inline-loader v-if="today_loading"></inline-loader>
                <div v-else-if="today_projects.length" class="list-group">
                    <template v-for="today_project in today_projects" :key="today_project.project_id">
                        <router-link
                            :to="{name: 'ProjectEdit', params: {id: today_project.project_id}}"
                            class="list-group-item list-group-item-action d-flex justify-content-between"
                        >
                            <strong>{{ today_project.customer.company_name }}</strong>
                            <div>
                                <span class="badge bg-light">{{ today_project.status_label }}</span>
                                <span class="badge bg-primary">{{ today_project.rank_label }}</span>
                            </div>
                        </router-link>
                    </template>
                </div>
                <div v-else class="list-group">
                    <p>該当 : 0件</p>
                </div>
            </section>
        </div>
        <div class="col-md-18 px-3 mb-3">
            <section class="section">
                <div class="section-title">
                    <h3><i class="bi bi-calendar-x text-danger"></i> 期限切れ</h3>
                    <div class="w-50">
                        <form-select-search
                            v-model="expired_follow_user_id"
                            :options="options_user"
                            placeholder="追客担当：全員"
                            @selected="fetchExpiredProjects()"
                        ></form-select-search>
                    </div>
                </div>
                <inline-loader v-if="expired_loading"></inline-loader>
                <div v-else-if="expired_projects.length" class="list-group">
                    <template v-for="expired_project in expired_projects" :key="expired_project.project_id">
                        <router-link
                            :to="{name: 'ProjectEdit', params: {id: expired_project.project_id}}"
                            class="list-group-item list-group-item-action d-flex justify-content-between"
                        >
                            <div>
                                <strong>{{ expired_project.customer.company_name }}</strong>
                                {{ expired_project.follow_user.user_name }}
                                <span class="text-danger">{{ expired_project.next_approaching_date_label }}</span>
                            </div>
                            <div>
                                <span class="badge bg-light">{{ expired_project.status_label }}</span>
                                <span class="badge bg-primary">{{ expired_project.rank_label }}</span>
                            </div>
                        </router-link>
                    </template>
                    <div v-if="expired_projects.length === 10" class="list-group-item">
                        ※古い順に最初の10件のみ表示しています。
                    </div>
                </div>
                <div v-else class="list-group">
                    <p>該当 : 0件</p>
                </div>
            </section>
        </div>
    </div>

    <div class="mb-4 mx-2">
        <section class="section">
            <div class="section-title">
                <h3><i class="bi bi-trophy-fill text-primary"></i> 受注速報</h3>
            </div>
            <inline-loader v-if="success_loading"></inline-loader>
            <div v-else-if="success_projects.length" class="row">
                <template v-for="success_project in success_projects" :key="success_project.project_id">
                    <div class="col-18 d-flex align-items-center py-1">
                        <template v-for="user in getActivityUsers(success_project.activities)" :key="user.user_id">
                            <template v-if="user.user_image_path">
                                <div class="me-2"><img :src="user.user_image_path" class="user_image_circle"></div>
                            </template>
                            <template v-else>
                                <div class="icon-circle me-2"></div>
                            </template>
                        </template>
                        <div class="px-2">
                            <div>
                                <span class="me-2">{{ getMonthAndDay(success_project.ordered_date_label) }}</span>
                                <strong class="text-primary">{{ success_project.department.department_name }}</strong>
                            </div>
                            <div>
                                <strong>{{ success_project.customer.company_name }}様</strong>
                                <span>{{ getActivityUsersDetail(success_project.activities) }}</span>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div v-else class="row">
                <p>該当 : 0件</p>
            </div>
        </section>
    </div>

    <div class="mb-3 mx-2">
        <inline-loader v-if="performance_loading"></inline-loader>
        <section v-else-if="performances !== null" class="section">
            <div class="section-title">
                <h3><i class="bi bi-bar-chart-line-fill text-primary"></i> 当月実績</h3>
                <div>
                    <button
                        @click.prevent="changeDepartment(null)"
                        class="btn department_tab"
                        :class="{'active': active_department === null, 'text-primary': active_department === null}"
                    >
                        {{ performances.total.department_name }}
                    </button>
                    <template v-for="department in performances.departments" :key="department.department_name">
                        <button
                            @click.prevent="changeDepartment(department.department_id)"
                            class="btn department_tab"
                            :class="{'active': active_department === department.department_id, 'text-primary': active_department === department.department_id}"
                        >
                            {{ department.department_name }}
                        </button>
                    </template>
                </div>
            </div>
            <template v-if="performances.departments.length">
                <template v-if="active_department === null">
                    <div class="row">
                        <div class="col-10 p-5 text-center offset-1">
                            <h4 class="h2">反響</h4>
                            <div class="display-6 text-primary"><strong class="display-2">{{ performances.total.contact_count }}</strong> 件</div>
                            <div>着地見込 : <strong class="h2 text-primary">{{ performances.total.contact_count_landing }}</strong> 件</div>
                            <div>前月対比 :
                                <strong class="h2" :class="getCompareTextColor(performances.total.contact_count_mom)">
                                    {{ performances.total.contact_count_mom }}
                                </strong> 件
                            </div>
                            <div class="mt-5">
                                <router-link :to="{name: 'ReportResponse'}" class="btn btn-primary">反響実績</router-link>
                            </div>
                        </div>
                        <div class="col-2 d-flex align-items-center justify-content-center"><div class="icon-flow"></div></div>
                        <div class="col-10 p-5 text-center">
                            <h4 class="h2">アポ</h4>
                            <div class="display-6 text-primary"><strong class="display-2">{{ performances.total.appointment_count }}</strong> 件</div>
                            <div>着地見込 : <strong class="h2 text-primary">{{ performances.total.appointment_count_landing }}</strong> 件</div>
                            <div>前月対比 :
                                <strong class="h2" :class="getCompareTextColor(performances.total.appointment_count_mom)">
                                    {{ performances.total.appointment_count_mom }}
                                </strong> 件
                            </div>
                            <div class="mt-5">
                                <router-link :to="{name: 'ReportRate'}" class="btn btn-primary">アポ率・受注率</router-link>
                            </div>
                        </div>
                        <div class="col-2 d-flex align-items-center justify-content-center"><div class="icon-flow"></div></div>
                        <div class="col-10 p-5 text-center">
                            <h4 class="h2">受注</h4>
                            <div class="display-6 text-primary"><strong class="display-2">{{ performances.total.ordered_count }}</strong> 件</div>
                            <div>着地予測 : <strong class="h2 text-primary">{{ performances.total.ordered_count_landing }}</strong> 件</div>
                            <div>前月対比 :
                                <strong class="h2" :class="getCompareTextColor(performances.total.ordered_count_mom)">
                                    {{ performances.total.ordered_count_mom }}
                                </strong> 件
                            </div>
                            <div class="mt-5">
                                <router-link :to="{name: 'ReportForecast'}" class="btn btn-primary">着地予想</router-link>
                            </div>
                        </div>
                    </div>
                </template>
                <div v-for="department in performances.departments" :key="department.department_name" class="row">
                    <template v-if="active_department === department.department_id">
                        <div class="col-10 p-5 text-center offset-1">
                            <h4 class="h2">反響</h4>
                            <div class="display-6 text-primary"><strong class="display-2">{{ department.contact_count }}</strong> 件</div>
                            <div>着地見込 : <strong class="h2 text-primary">{{ department.contact_count_landing }}</strong> 件</div>
                            <div>前月対比 :
                                <strong class="h2" :class="getCompareTextColor(department.contact_count_mom)">
                                    {{ department.contact_count_mom }}
                                </strong> 件
                            </div>
                            <div class="mt-5">
                                <router-link :to="{name: 'ReportResponse'}" class="btn btn-primary">反響実績</router-link>
                            </div>
                        </div>
                        <div class="col-2 d-flex align-items-center justify-content-center"><div class="icon-flow"></div></div>
                        <div class="col-10 p-5 text-center">
                            <h4 class="h2">アポ</h4>
                            <div class="display-6 text-primary"><strong class="display-2">{{ department.appointment_count }}</strong> 件</div>
                            <div>着地見込 : <strong class="h2 text-primary">{{ department.appointment_count_landing }}</strong> 件</div>
                            <div>前月対比 :
                                <strong class="h2" :class="getCompareTextColor(department.appointment_count_mom)">
                                    {{ department.appointment_count_mom }}
                                </strong> 件
                            </div>
                            <div class="mt-5">
                                <router-link :to="{name: 'ReportRate'}" class="btn btn-primary">アポ率・受注率</router-link>
                            </div>
                        </div>
                        <div class="col-2 d-flex align-items-center justify-content-center"><div class="icon-flow"></div></div>
                        <div class="col-10 p-5 text-center">
                            <h4 class="h2">受注</h4>
                            <div class="display-6 text-primary"><strong class="display-2">{{ department.ordered_count }}</strong> 件</div>
                            <div>着地予測 : <strong class="h2 text-primary">{{ department.ordered_count_landing }}</strong> 件</div>
                            <div>前月対比 :
                                <strong class="h2" :class="getCompareTextColor(department.ordered_count_mom)">
                                    {{ department.ordered_count_mom }}
                                </strong> 件
                            </div>
                            <div class="mt-5">
                                <router-link :to="{name: 'ReportForecast'}" class="btn btn-primary">着地予想</router-link>
                            </div>
                        </div>
                    </template>
                </div>
            </template>
            <template v-else>
                <div class="row">
                    <p>該当 : 0件</p>
                </div>
            </template>
        </section>
    </div>
</template>

<script>
import InlineLoader from '@/components/tools/InlineLoader.vue';
import FormSelectSearch from '@/components/forms/FormSelectSearch';
import ActivityType from '@/models/enums/activity-type';
import User from '@/models/entities/user';
import Project from '@/models/entities/project';

export default {
    name: 'Home',
    components: {
        InlineLoader,
        FormSelectSearch,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
        'showErrorMessage'
    ],
    data() {
        return {
            // ローディング
            today_loading: false,
            expired_loading: false,
            success_loading: false,
            performance_loading: false,

            // 表示データ
            today_projects: [],
            expired_projects: [],
            success_projects: [],
            performances: null,

            // ログイン中ユーザ
            user: null,

            // ユーザマスタ
            options_user: [],

            // 選択中の事業部
            active_department: null,
        }
    },
    computed: {
        // 期限切れリストの対象ユーザID
        expired_follow_user_id: {
            get() {
                return this.$store.state.dashboard.expired_follow_user_id;
            },
            set(value) {
                this.$store.commit('dashboard/setExpiredFollowUserId', value);
            }
        },
    },
    mounted() {
        this.search();
    },
    methods: {
        getMonthAndDay(date) {
            if (date) {
                return this.$helper.nj(date);
            }
        },
        getActivityUsers(activities) {
            let users = activities.map((activity) => {
                if (activity.activity_type === ActivityType.APPOINTMENT) {
                    return activity.user;
                } else if (activity.activity_type === ActivityType.MEETING) {
                    return activity.user;
                }
            }).filter(Boolean);

            return Array.from(
                new Map(users.map((user) => [user.user_id, user])).values()
            );
        },
        getActivityUsersDetail(activities) {
            let activity_users = activities.map((activity) => {
                let activity_user_detail = '';
                if (activity.activity_type === ActivityType.APPOINTMENT) {
                    activity_user_detail = 'アポ：' + activity.user.user_name;
                } else if (activity.activity_type === ActivityType.MEETING) {
                    activity_user_detail = '営業：' + activity.user.user_name;
                }
                return activity_user_detail;
            }).filter(Boolean);
            let filtered_activity_users = Array.from(new Set(activity_users)).sort();

            let display_activity_users = '';
            filtered_activity_users.forEach((user, index) => {
                if (index === 0) {
                    display_activity_users = display_activity_users + user + ' さん';
                } else {
                    display_activity_users = display_activity_users + '、' + user + ' さん';
                }
            });
            return ' ( ' + display_activity_users + ' )';
        },
        changeDepartment (tab_number) {
            this.active_department = tab_number;
        },
        getCompareTextColor(mon) {
            return Math.sign(Number(mon)) >= 0 ? 'text-success' : 'text-danger';
        },
        async search() {
            this.fetchUserOptions();
            this.fetchLoginUser();
            this.fetchTodayProjects();
            this.fetchExpiredProjects();
            this.fetchSuccessProjects();
            this.fetchPerformanceProjects();
        },
        fetchUserOptions() {
            return new Promise(resolve => {
                // リセット
                this.options_user.splice(0);

                this.$http.get('/user')
                .then(response => {
                    for (let row of response.data) {
                        let user = new User(row);
                        this.options_user.push({value: user.user_id, label: user.user_name});
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        fetchLoginUser() {
            return new Promise(resolve => {
                this.$http.get('/me')
                .then(response => {
                    this.user = new User(response.data);
                    this.active_department = this.user.department.department_id ?? null;
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        // 自分が担当で、次回アプローチ日が本日の案件取得
        fetchTodayProjects() {
            this.today_loading = true;

            this.today_projects.splice(0);

            this.$http.get('/project/today')
            .then(response => {
                this.today_projects = response.data.map((project) => {
                    return new Project(project);
                });
            })
            .finally(() => {
                this.today_loading = false;
            })
        },
        // 次回アプローチ日が過去の案件取得
        fetchExpiredProjects() {
            this.expired_loading = true;
            this.expired_projects.splice(0);

            this.$http.get('/project/expired', {params: {follow_user_id: this.expired_follow_user_id}})
            .then(response => {
                this.expired_projects = response.data.map((project) => {
                    return new Project(project);
                });
            })
            .finally(() => {
                this.expired_loading = false;
            })
        },
        // 受注済みの案件取得
        fetchSuccessProjects() {
            this.success_loading = true;
            this.success_projects.splice(0);

            this.$http.get('/project/success')
            .then(response => {
                this.success_projects = response.data.map((project) => {
                    return new Project(project);
                });
            })
            .finally(() => {
                this.success_loading = false;
            })
        },
        // 受注済みの案件取得
        fetchPerformanceProjects() {
            this.performance_loading = true;
            this.performances = null;

            this.$http.get('/report/performance')
            .then(response => {
                this.performances = response.data;
            })
            .finally(() => {
                this.performance_loading = false;
            })
        },
    },
}
</script>

<style scoped>
.icon-circle {width: 3rem; height: 3rem; flex-shrink: 0; border-radius: 1.5rem; border: 1px solid #CCC; background: #EEE;}
.user_image_circle {width: 3rem; height: 3rem; flex-shrink: 0; border-radius: 1.5rem; border: 1px solid #CCC;}
.icon-flow {width: 40px; height: 160px; border-left: 40px solid var(--bs-primary); border-top: 80px solid transparent; border-bottom: 80px solid transparent;}
</style>
